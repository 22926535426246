import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import dayjsTimezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getSupportedUserLocale } from "src/lib/i18next";
import leftPadNumber from "src/lib/leftPadNumber";

dayjs.extend(dayjsTimezone);

// eslint-disable-next-line @typescript-eslint/unbound-method
export const dateGuessTz = dayjs.tz.guess;

dayjs.extend(utc);

export const INPUT_US_REGEX = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

type CustomDateType = Date | dayjs.Dayjs | number | string | null | undefined;

export const dateUtc = (d?: CustomDateType) => {
  // remove tz from d
  return dayjs.utc(d);
};

export const dateLocal = (d?: CustomDateType, f?: dayjs.OptionType) => {
  // remove tz from d
  return dayjs(d, f).tz(dateGuessTz());
};

export enum DateFormats {
  ISO_YMD = "YYYY-MM-DD",
  ISO_YMDHMS = "YYYY-MM-DDTHH:mm:ss",
  ISO_FULL = "YYYY-MM-DDTHH:mm:ssZ",
  INPUT_US = "MM/DD/YYYY",
  DISPLAY_TIME = "h:mm A",
  DISPLAY_US_MDT = "MMM. D, hh:mma",
  DISPLAY_US_MDY = "MMMM D, YYYY",
  DISPLAY_US_MDYT = "MM/DD/YY, h:mm A",
  DISPLAY_US_MDY_SHORT = "MM/DD/YY"
}

function format(
  timestamp?: Date | string,
  dateFormat = "MMMM D hh:mma"
): string {
  return dateUtc(timestamp).format(dateFormat);
}

function convert(
  dateString: string,
  from: DateFormats,
  to: DateFormats
): string {
  if (!dateString) {
    return "";
  }

  // shorten the date string to the length of the format
  const fromShort = dateString.substring(0, from.length);

  const d = dateLocal(fromShort, from);
  return d.format(to);
}

function localToUtc(d: Dayjs): Dayjs {
  return d.utc();
}

const date = {
  format,
  convert,
  localToUtc,
  /*
   * ignores any timezone information from the string, if it says "12:12" it will stay "12:12" in the result regardless of the timezone
   * use this to display dates as they were when recorded
   */
  isoStringDisplayDate: (isoDateString: string, timezone = ""): Dayjs => {
    const parts = date.extractDateTimeComponents(isoDateString);
    const str = `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}${timezone}`;
    return dayjs(str);
  },

  /**
   * Takes an ISO date string and returns the components of the date
   * @param isoDateString
   */
  extractDateTimeComponents: (
    isoDateString: string
  ): {
    year: number;
    month: string;
    day: string;
    hour: string;
    minute: string;
    second: string;
    utcOffsetMinutes: number;
    hasTime: boolean;
    hasTimezone: boolean;
    isValid: boolean;
  } => {
    let isoString = isoDateString.trim();
    let hasTimezone = undefined;

    // remove ".000Z" or "Z" from the end of the string https://regex101.com/r/AOjyHl/1
    const dotZ = /(\.\d{3})?Z$/;
    if (dotZ.test(isoString)) {
      isoString = isoString.replace(dotZ, "");
      hasTimezone = true;
    }

    // remove milliseconds from the end of the string
    const dotMs = /\.\d(\d)?(\d)?/;
    if (dotMs.test(isoString)) {
      isoString = isoString.replace(dotMs, "");
    }

    // regex for ISO 8601 DateTime format https://regex101.com/r/fjaSi0/1
    const match =
      /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2})([+-]\d{2}:\d{2})?)?$/.exec(
        isoString
      );

    if (!match) {
      return {
        year: 0,
        month: "00",
        day: "00",
        hour: "00",
        minute: "00",
        second: "00",
        utcOffsetMinutes: 0,
        hasTime: false,
        hasTimezone: false,
        isValid: false
      };
    }

    const year = Number(match[1]);
    const month = leftPadNumber(Number(match[2]), 2);
    const day = leftPadNumber(Number(match[3]));
    const hour = leftPadNumber(match[5] ? Number(match[5]) : 0, 2);
    const minute = leftPadNumber(match[6] ? Number(match[6]) : 0, 2);
    const second = leftPadNumber(match[7] ? Number(match[7]) : 0, 2);

    const minusOffset = match[8] && match[8].startsWith("-");
    const utcOffsetMinutes = match[8]
      ? (Number(match[8].substring(1, 3)) * 60 +
          Number(match[8].substring(4, 6))) *
        (minusOffset ? -1 : 1)
      : 0;

    return {
      year,
      month,
      day,
      hour,
      minute,
      second,
      utcOffsetMinutes,
      hasTime: Boolean(match[4]) && !String(match[4]).includes("T00:00:00"),
      hasTimezone: hasTimezone ?? Boolean(match[8]),
      isValid: true
    };
  },

  getUserTimezoneOffset: (): { minutes: number; string: string } => {
    const minutes = dateLocal().utcOffset();
    const sign = minutes < 0 ? "-" : "+";
    const hours = Math.floor(Math.abs(minutes) / 60);
    const minutesLeft = Math.abs(minutes) % 60;
    const string = `${sign}${leftPadNumber(hours)}:${leftPadNumber(
      minutesLeft
    )}`;
    return { minutes, string };
  }
};

export default date;

const JUST_NOW_THRESHOLD = 1000 * 60; // 1 minutes

export const humanizeDuration = (
  duration: number,
  options: { justNow?: boolean; shorten?: boolean } = {}
): string => {
  const { justNow = false, shorten = false } = options;

  if (justNow && duration < JUST_NOW_THRESHOLD) {
    return "just now";
  }

  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((duration / 1000 / 60) % 60);
  const seconds = Math.floor((duration / 1000) % 60);

  const daysString = days > 0 ? `${days}d ` : "";
  const hoursString = hours > 0 ? `${hours}h ` : "";
  const minutesString = minutes > 0 ? `${minutes}m ` : "";
  const secondsString = seconds > 0 ? `${seconds}s ` : "";

  const all = [daysString, hoursString, minutesString, secondsString];

  return shorten
    ? all
        .filter((s) => s.length > 0)
        .slice(0, 2)
        .join("")
    : all.join("");
};

interface ExtendedDateTimeFormatOptions
  extends Omit<Intl.DateTimeFormatOptions, "timeStyle"> {
  timeStyle?: Intl.DateTimeFormatOptions["timeStyle"] | "auto";
}

export const dateLocalString = (
  d?: CustomDateType,
  options: ExtendedDateTimeFormatOptions = {
    dateStyle: "long",
    timeStyle: "auto",
    hourCycle: "h12"
  }
): string => {
  if (!d) {
    return "";
  }

  const dayjsDate = dateLocal(d);
  const dateObject = dayjsDate.toDate();
  const dateString = d.toString();
  const { hasTime } = date.extractDateTimeComponents(dateString);
  const timeStyleFormatAuto = hasTime ? "short" : undefined;

  const modifiedOptions = {
    ...options,
    timeStyle:
      options.timeStyle === "auto" ? timeStyleFormatAuto : options.timeStyle
  } satisfies Intl.DateTimeFormatOptions;

  return dateObject.toLocaleString(getSupportedUserLocale(), modifiedOptions);
};
